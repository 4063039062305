import {version} from './version'

function requireEnv(env, cast) {
	if (!process.env.hasOwnProperty(env)) {
		throw new Error(`Missing ENV variable ${env}`)
	}

	return getEnv(env, cast)
}

function getEnv(env, cast = null, defaultValue = null) {
	let value = null

	if (!process.env.hasOwnProperty(env) || !process.env[env]) {
		value = defaultValue
	} else {
		value = process.env[env]
	}

	if (cast && value !== null) {
		value = cast(value)
	}

	return value
}

const caster = {
	int: (val) => parseInt(val, 10),
	bool: (val) => {
		let valType = typeof (val)

		if (valType === 'string') {
			val = val.toUpperCase()

			return val === 'TRUE' || val === '1'
		} else if (valType === 'number') {
			return val > 0
		} else if (valType === 'boolean') {
			return val
		} else {
			return !!val
		}
	},
}

let location = window.location

let isSecure = location.protocol && location.protocol === 'https:'

let defaultValues = {
	colyseusHost: location.hostname,
	colyseusPort: location.port || (isSecure ? 443 : 80),
	colyseusSecure: isSecure,
	secureApiEndPoint: isSecure,
}

const Config = {
	version: version + ' ' + getEnv('NODE_ENV'),
	componentTransition: 'fade',

	apiEndPointBaseUrl: getEnv('VUE_APP_END_POINT_BASE_URL'),
	backendUrl: getEnv('VUE_APP_BACKEND_URL'),

	configName: requireEnv('NODE_ENV'),
	colyseusHost: getEnv('VUE_APP_COLYSEUS_HOST', null,
		defaultValues.colyseusHost),
	colyseusPort: getEnv('VUE_APP_COLYSEUS_PORT', caster.int,
		defaultValues.colyseusPort),
	colyseusSecure: getEnv('VUE_APP_COLYSEUS_SECURE', caster.bool,
		defaultValues.colyseusSecure),
	secureApiEndPoint: getEnv('VUE_APP_API_ENDPOINT_SECURE', caster.bool,
		defaultValues.secureApiEndPoint),
	autoThemeSelection: getEnv('VUE_APP_AUTO_THEME_SELECTION', caster.int,
		null),
	autoCaseSelection: getEnv('VUE_APP_AUTO_CASE_SELECTION', caster.int, null),
	autoLobbyStart: getEnv('VUE_APP_AUTO_LOBBY_START', caster.bool, false),
	forceRoomCode: getEnv('VUE_APP_FORCE_ROOM_CODE'),
	throwApiError: getEnv('VUE_APP_THROW_API_ERROR', caster.bool, false),
	showDebugPanel: getEnv('VUE_APP_SHOW_DEBUG_PANEL', caster.bool, false),
	isDev: getEnv('VUE_APP_IS_DEV', caster.bool, false),

	staticGameClientType: getEnv('VUE_APP_STATIC_GAME_CLIENT_TYPE'),

	studentUrl: getEnv('VUE_APP_STUDENT_URL'),
	studentUrlSimple: getEnv('VUE_APP_STUDENT_URL_SIMPLE') ||
		getEnv('VUE_APP_STUDENT_URL'),

	debug: {
		config: getEnv('VUE_APP_DEBUG_CONFIG', caster.bool, false),
		gameClient: getEnv('VUE_APP_DEBUG_GAMECLIENT', caster.bool, false),
		api: getEnv('VUE_APP_DEBUG_API', caster.bool, false),
		teacherController: getEnv('VUE_APP_DEBUG_TEACHER_CONTROLLER',
			caster.bool, false),
		studentController: getEnv('VUE_APP_DEBUG_STUDENT_CONTROLLER',
			caster.bool, false),
		singlePlayerController: getEnv('VUE_APP_DEBUG_SINGLE_PLAYER_CONTROLLER',
			caster.bool, false),
		adminController: getEnv('VUE_APP_DEBUG_ADMIN_CONTROLLER', caster.bool,
			false),
	},

	features: {
		dilemma: getEnv('VUE_APP_FEATURE_DILEMMA', caster.bool, true),
		roleplay: getEnv('VUE_APP_FEATURE_ROLEPLAY', caster.bool, true),
		automaticResults: getEnv('VUE_APP_FEATURE_AUTOMATIC_RESULTS',
			caster.bool, true),

		maxChoices: getEnv('VUE_APP_FEATURE_CHOICES_MAX', caster.int, 4),
		roomIdLength: getEnv('VUE_APP_FEATURE_ROOM_ID_LENGTH', caster.int, 4),

		autoJoin: getEnv('VUE_APP_FEATURE_AUTO_JOIN_ROOM', caster.bool, false),

		showStudentUrl: getEnv('VUE_APP_SHOW_STUDENT_URL', caster.bool, true),
		showStudentUrlQr: getEnv('VUE_APP_SHOW_STUDENT_URL_QR', caster.bool,
			true),

		backButton: getEnv('VUE_APP_FEATURE_BACK_BUTTON', caster.bool, true),
		backButtonDefault: getEnv('VUE_APP_FEATURE_BACK_BUTTON_DEFAULT',
			caster.bool, false),

		themeCaseAutoSelect: getEnv('VUE_APP_FEATURE_THEME_CASE_AUTOSELECT',
			caster.bool, true),
		customIdentifier: getEnv('VUE_APP_FEATURE_CUSTOM_IDENTIFIER',
			caster.bool, false),
		themeDescription: getEnv('VUE_APP_FEATURE_THEME_DESCRIPTION',
			caster.bool, true),
		caseDescription: getEnv('VUE_APP_FEATURE_CASE_DESCRIPTION', caster.bool,
			true),
		caseDeepLink: getEnv('VUE_APP_FEATURE_CASE_DEEP_LINK', caster.bool,
			false),
		caseState: getEnv('VUE_APP_FEATURE_CASE_STATE', caster.bool, true),

		themeAutoSelectIfOnlyOne: getEnv(
			'VUE_APP_FEATURE_THEME_AUTO_SELECT_IF_ONLY_ONE', caster.bool, true),
		caseAutoSelectIfOnlyOne: getEnv(
			'VUE_APP_FEATURE_CASE_AUTO_SELECT_IF_ONLY_ONE', caster.bool, true),

		components: {
			dilemma: {
				text: getEnv('VUE_APP_FEATURE_COMPONENT_DILEMMA_TEXT',
					caster.bool, true),
				points: getEnv('VUE_APP_FEATURE_COMPONENT_DILEMMA_POINTS',
					caster.bool, true),
				phone: getEnv('VUE_APP_FEATURE_COMPONENT_DILEMMA_PHONE',
					caster.bool, true),
				rawMedia: getEnv('VUE_APP_FEATURE_COMPONENT_DILEMMA_RAW_MEDIA',
					caster.bool, true),
				choices: getEnv('VUE_APP_FEATURE_COMPONENT_DILEMMA_CHOICES',
					caster.bool, true),
				separateResult: getEnv(
					'VUE_APP_FEATURE_COMPONENT_DILEMMA_SEPARATE_RESULT',
					caster.bool, false),
				showSensitiveContent: getEnv(
					'VUE_APP_FEATURE_COMPONENT_DILEMMA_SHOW_SENSITIVE_CONTENT',
					caster.bool, false),
			},
			roleplay: {
				text: getEnv('VUE_APP_FEATURE_COMPONENT_ROLEPLAY_TEXT',
					caster.bool, true),
				points: getEnv('VUE_APP_FEATURE_COMPONENT_ROLEPLAY_POINTS',
					caster.bool, true),
				phone: getEnv('VUE_APP_FEATURE_COMPONENT_ROLEPLAY_PHONE',
					caster.bool, true),
				rawMedia: getEnv('VUE_APP_FEATURE_COMPONENT_ROLEPLAY_RAW_MEDIA',
					caster.bool, true),
				choices: getEnv('VUE_APP_FEATURE_COMPONENT_ROLEPLAY_CHOICES',
					caster.bool, true),
				separateResult: getEnv(
					'VUE_APP_FEATURE_COMPONENT_ROLEPLAY_SEPARATE_RESULT',
					caster.bool, false),
				showSensitiveContent: getEnv(
					'VUE_APP_FEATURE_COMPONENT_ROLEPLAY_SHOW_SENSITIVE_CONTENT',
					caster.bool, false),
			},
		},
	},
}

console.log('Config', Config)

console.log('location', location)
console.log('defaultValues', defaultValues)

export default Config
